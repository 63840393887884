<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import { SideBar } from '@/core/config/menu/sideBar';

export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.PROFILE);
  }
};
</script>
<style scoped>
.form-group label {
  font-weight: 600;
}
</style>
